import { ShoppingListItem as Item } from "../../../services/ShoppingListService";
import styles from './ShoppingListItem.module.css';

interface ShoppingListItemProps {
  item: Item;
  onItemToggle: (item: Item) => void;
}
export const ShoppingListItem = (props: ShoppingListItemProps) => {

  const cn = [styles.item];
  cn.push(props.item.collected ? styles.collected : styles['not-collected']);

  return (
    <div className={styles.btnGroup}>
      <div className={cn.join(' ')} onClick={() => props.onItemToggle(props.item)}>
       { props.item.collected && '✔ ' }
       { props.item.name }
       { props.item.quantity && <div> {props.item.quantity} </div> }
       { props.item.extraInfo && <div> {props.item.extraInfo} </div> }
      </div>
    </div>
  );
}