import { NextPickupDate } from '../../services/GarbageApiService';
import './GarbageSchedule.css';
import { DateTime } from 'luxon';


interface GarbageScheduleProps {
  schedule: NextPickupDate
}
const GarbageSchedule = ({ schedule }: GarbageScheduleProps) => {
  let className = '';
  
  if (schedule.daysFromToday === 0) {
    className='today';
  } else if (schedule.daysFromToday === 1) {
    className='tomorrow';
  }
  const dayOfWeek = DateTime.fromISO(schedule.date).setLocale('pl').weekdayLong;

  return (
    <div className="wasteCard">
      <h3>{schedule.label}</h3>
      <div className={className}>{schedule.humanFriendly}</div>
      <div>{dayOfWeek}</div>
    </div>
  )
}


export default GarbageSchedule