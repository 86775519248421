import WhatToEat from "./WhatToEat/WhatToEat"

export const WhatToCook = () => {

  return (
    <div>
      <h1>Co ugotować?</h1>
      <WhatToEat title="Śniadanko" group="breakfast"/>
      <WhatToEat title="Obiadki" group="dinner"/>
      <WhatToEat title="Kolacje" group="supper"/>
      <WhatToEat title="Smakołyki" group="snacks"/>
    </div>
  )
}
