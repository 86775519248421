import { useState } from "react";
import { KeyValuePair, keyValueStore } from "../../../services/KeyValueStore";

interface EditWhatToEastProps {
  items: KeyValuePair[];
  group: string;
  onUpdate: () => void;
}

export const EditWhatToEat = (props: EditWhatToEastProps) => {
  const [expanded, setExpanded] = useState(false);
  const [newItem, setNewItem] = useState('');
  const handleDelete = (item: KeyValuePair) => {
    keyValueStore.delete(item.id)
    .then(props.onUpdate);
  }
  const handleAdd = () => {
    if (newItem.trim() !==  '') {
      keyValueStore.add(newItem, props.group)
        .then(() => {
          setNewItem('');
          props.onUpdate()
        });
    }
  }
  
  if (!expanded) {
    return (<div className="btn" onClick={() => setExpanded(true)}>Edytcja</div>)
  } else {
    const items = props.items.map(item => <div key={item.id}>
      {item.value} 
      <span style={{color: 'red'}} onClick={() => handleDelete(item)}> ✖</span>
    </div>);

    return (
      <div>
        {items}
        <div className="card">
          <label>Potrawa:</label>
          <input type="text" onChange={(e) => setNewItem(e.target.value)} value={newItem}/>
          <div className="btn" onClick={handleAdd}>Dodaj</div>
        </div>
        <div className="btn" onClick={() => setExpanded(false)}>Zamknij</div>
      </div>
    )
  }
}