import { useEffect, useState } from 'react';
import './WhatToEat.css';
import { KeyValuePair, keyValueStore } from '../../../services/KeyValueStore';
import { EditWhatToEat } from './EditWhatToEat';


interface WhatToEatProps {
  title: string;
  group: string;
}

const WhatToEat = ({ title, group } : WhatToEatProps) => {
  const [items, setItems] = useState<KeyValuePair[]>([]);
  const [item, setItem] = useState<KeyValuePair | undefined>(undefined);

  const loadItems = () => {
    keyValueStore.getAllByGroup(group)
      .then((items) => setItems(items));
  }

  useEffect(loadItems, [group]);

  const elements = items.map(item => <div key={item.id}>{item.value}</div>);
  const propose = () => {
    const index = Math.floor(Math.random() * items.length);
    setItem(items[index]);
  }

  return (
    <div className="what-to-eat">
      <h2>{title}</h2>
      <div className='inlineForm'>
        <div className='btn' onClick={propose}>Losuj</div>
        <EditWhatToEat items={items} group={group} onUpdate={loadItems} />
      </div>
      {item && <h1>{item.value}</h1>}
    </div>
  );
};

export default WhatToEat;
