import { AddItem } from "../components/ShoppingList/AddShoppingListItem/AddShoppingListItem";

const host = process.env.REACT_APP_BACKEND_URL;

interface ShoppingListItem {
  id: number;
  name: string;
  category: string;
  quantity?: string;
  extraInfo?: string;
  selected: boolean;
  collected: boolean;
}

interface ItemsByCategory {
  category: string;
  items: ShoppingListItem[];
}

class ShoppingListService {
  mapToItemsByCategory = (items: ShoppingListItem[]): ItemsByCategory[] => {
    const byCategories = {} as { [category: string]: ShoppingListItem[] };

    items.forEach(item => {
      if (!byCategories[item.category]) {
        byCategories[item.category] = [];
      }
      byCategories[item.category].push(item);
    });
    return Object.keys(byCategories).map(category => ({
      category,
      items: byCategories[category]
    }));
  }

  getList(): Promise<ItemsByCategory[]> {
    return fetch(host + '/shoppingList/items')
      .then(response => response.json() as Promise<ShoppingListItem[]>)
      .then(this.mapToItemsByCategory);
  }

  async toggle(item: ShoppingListItem): Promise<void> {
    fetch(host + '/shoppingList/toggle', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item)
    });
  }

  add(item: AddItem): Promise<ItemsByCategory[]> {
    return fetch(host + '/shoppingList/add', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item)
    })
    .then(response => response.json() as Promise<ShoppingListItem[]>)
    .then(this.mapToItemsByCategory);
  }

  aiAdd(transcript: string): Promise<ItemsByCategory[]> {
    return fetch(host + '/shoppingList/ai-add', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ input: transcript })
    })
    .then(response => response.json() as Promise<ShoppingListItem[]>)
    .then(this.mapToItemsByCategory);
  }

  delete(itemId: number): Promise<ItemsByCategory[]> {
    return fetch(`${host}/shoppingList/delete?id=${itemId}`, {
      method: "DELETE"
    })
    .then(response => response.json() as Promise<ShoppingListItem[]>)
    .then(this.mapToItemsByCategory);
  }

  unselectCollected(): Promise<ItemsByCategory[]> {
    return fetch(`${host}/shoppingList/unselectCollected`, {
      method: "POST"
    })
    .then(response => response.json() as Promise<ShoppingListItem[]>)
    .then(this.mapToItemsByCategory);
  }
}

export const shoppingListService = new ShoppingListService();
export type { ItemsByCategory, ShoppingListItem };
