import { ShoppingListItem as Item } from "../../../services/ShoppingListService";
import { ShoppingListItem } from "../Item/ShoppingListItem";
import styles from './Category.module.css';

interface CategoryPros {
  items: Item[];
  name: string;
  onItemToggle: (item: Item) => void;
}

export const Category = (props: CategoryPros) => {
  const shouldDisplayItem = (item: Item) => item.selected

  const items = props.items
    .filter(shouldDisplayItem)
    .map((item) => <ShoppingListItem 
        key={item.id}
        item={item}
        onItemToggle={props.onItemToggle}/>);

  return !items.length ? null : (
    <div>
      <h2>{props.name}</h2>
      <div className={styles.itemsContainer}>
        {items}
      </div>
    </div>);
}