
import {
  Link,
  Route,
  Routes
} from "react-router-dom";
import './App.css';
import Garbage from './components/Garbage/Garbage';
import ShoppingList from "./components/ShoppingList/ShoppingList";
import { WhatToCook } from "./components/WhatToCook/WhatToCook";


export default function App() {
  return (
    <>
      <nav>
        <Link to="/">Śmieci</Link>
        <Link to="/what-to-cook">Co ugotować</Link>
        <Link to="/shopping-list">Lista zakupów</Link>
      </nav>
      <Routes >
        <Route path="/" element={<Garbage/>}/>
        <Route path="/what-to-cook" element={<WhatToCook/>}/>
        <Route path="/shopping-list" element={<ShoppingList/>}/>
      </Routes>
    </>
  );
}
