import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Duration } from "luxon";
import { useEffect, useRef, useState } from "react";
import garbageApiService, { Schedule } from "../../services/GarbageApiService";
import './Garbage.css';
import GarbageSchedule from "./GarbageSchedule";

const RELOAD_INTERVAL = Duration.fromObject({ hours: 3 }).as('milliseconds')

const Garbage = () => {
  const lastLoadRef = useRef<number>(0);
  const [schedule, setSchedule] = useState<Schedule>()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBE4ai4HrEOKMmnV9LDZF3gEserjBeWqm8",
  });
  const home = { lat: 51.12093648858623, lng: 16.92525468488411 };

  const loadSchedule = () => {
    garbageApiService.loadSchedule().then(schedule => {
      setSchedule(schedule);
      lastLoadRef.current = Date.now();
    });
  }

  useEffect(() => {
    loadSchedule();
    const interval = setInterval(() => {
      const timeSinceLastLoad = Date.now() - lastLoadRef.current;
      if (timeSinceLastLoad > RELOAD_INTERVAL) {
        loadSchedule();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const bulkyMarkers = schedule?.bulky.map((item) => (
    <Marker position={{ lat: +item.lat, lng: +item.lng }} 
      label={{
        text: item.title + '    ' + item.dates[0],
        className: 'bulky-marker-label'
      }}
      key={item.point_id} />
  ));

  const map = (
    <div className="map-wrapper">
      {!isLoaded ? (
        <h1>Wczytywanie mapy ...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={home}
          zoom={16}
        >
          <Marker position={home} key="home"/>
          {bulkyMarkers}
        </GoogleMap>
      )}
    </div>
  )

  const pickupDates = schedule?.nextPickupDates.map(pickup => (
    <GarbageSchedule schedule={pickup} key={pickup.label + pickup.date}/>
  ))

  return (
    <div>
      { schedule
        ? (<div style={{display: 'flex', flexDirection: 'row'}}> {pickupDates} </div>)
        : <h1>Wczytywanie harmonogramu ...</h1>
      }
      { map }
    </div>
  )
}

export default Garbage
