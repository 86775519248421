import { DateTime } from "luxon";

type MapMarker = {
  point_id: string;
  title: string;
  lat: string;
  lng: string;
  sector_id: string;
  estate_id: string;
  nr_zlecenia: string;
  dates: string[];
  distance: number;
}

export interface NextPickupDate {
  type: string;
  label: string;
  date: string;
  humanFriendly: string;
  daysFromToday: number;
}

export interface Schedule {
  nextPickupDates: NextPickupDate[];
  bulky: MapMarker[]
}


const host = process.env.REACT_APP_BACKEND_URL;

class GarbageApiService {
  wasteLabels: { [key: string]: string } = {
    glass: 'Szkło',
    bio: 'Zielone',
    mixed: 'Zmieszane',
    paper: 'Papier',
    plastic: 'Plastik',
    bulky: 'Gabaryty'
  }

  async loadSchedule(): Promise<Schedule> {
    return fetch(host + '/garbage/schedule')
      .then(response => response.json())
      .then(schedule => {
        const nextPickupDates = [];

        for (const wasteType in schedule) {
          if (wasteType === 'bulky') {
            continue;
          }

          const data = schedule[wasteType].sort();
          const date = data.length ? data[0] : null;
          const daysFromToday = DateTime.fromISO(date).diff(DateTime.local().startOf('day'), 'days').days;

          const nextPickDay: NextPickupDate = {
            type: wasteType,
            label: this.wasteLabels[wasteType],
            date,
            daysFromToday,
            humanFriendly: DateTime.fromISO(date).setLocale('pl').toRelativeCalendar() || '',
          }

          if (nextPickDay.daysFromToday >= 0) {
            nextPickupDates.push(nextPickDay);
          }
        }

        nextPickupDates.sort((a, b) => a.daysFromToday - b.daysFromToday);

        return {
          nextPickupDates,
          bulky: schedule.bulky
        };
      })
  }
}

const garbageApiService = new GarbageApiService();

export default garbageApiService;