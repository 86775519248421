const host = process.env.REACT_APP_BACKEND_URL;

export interface KeyValuePair {
  id: string;
  value: string;
}

class KeyValueStore {
  public getAllByGroup(group: string): Promise<KeyValuePair[]> {
    return fetch(`${host}/store/getAll?group=${group}`)
      .then(response => response.json());
  }

  public delete(id: string): Promise<void> {
    return fetch(`${host}/store/delete?id=${id}`, { method: 'DELETE' })
      .then();
  }

  public add(value: string, group: string): Promise<void> {
    return fetch(`${host}/store/insert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ value, group })
    })
      .then();
  }
}

export const keyValueStore = new KeyValueStore();